import React from "react"

import PageBuilder from "../components/PageBuilder"
import SEO from "../components/seo"

const NotFoundPage = ({ pageContext }) => (
  <PageBuilder {...pageContext}>
    <SEO title="404: Not found" />
    <h1 style={{ textAlign: "center" }}>Could not find this page</h1>
  </PageBuilder>
)

export default NotFoundPage
